<template>
  <!-- begin::Page loader -->
  <div class="content-loader" :class="loaderClass" :style="loaderStyle">
    <span v-if="text" class="text mr-2">
      {{ text }}
    </span>
    <span class="spinner" :class="spinnerClass || 'spinner-primary'"></span>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
  export default {
    name: 'Loader',
    props: {
      loaderClass: {
        type: String,
        default: 'absolute'
      },
      loaderStyle: {
        type: [
          Boolean,
          String
        ],
        default: false
      },
      spinnerClass: {
        type: String,
        default: ''
      },
      text: {
        type: [
          Boolean,
          String
        ],
        default: false
      }
    }
  }
</script>
